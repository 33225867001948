
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index1ooULmmUs0UTY_0Tl1A_45jTIeIktBw3FRxPZ6t83nTGoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginEJJlFEB7TOPpkA6v_45U_Qx8Mihk_45kTWbbla9c0MiuZagMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyywiSXJUe2_N2W2omdxGbW3HLuvCa0La7em5P0wUnN3sMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapwdOFnQ6ZpK4Yxsx7DbousmYuVrH4xZSLsqdRpAb5GVgMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usSKZ3Z0YZzb_45_YUBP07VnMaNW13eg191DBnJdjBPnXuoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerrCn_7oXc56TD1xXA9ykg1ef_45NKilWc_JhTGxpGNGlisMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsBHbG0BpoKkWyw1UU_45haLyMgJsobSaGf7XUX0jo_45Cdf8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45uscGkkfBcmoNk2mPYGEIOZra9jscSpFrE1cnZhiV_Wc_450Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexa3nls0ALBiXlcBVBxMC9mHw01sueYE56ZpbZ8_45OXaicMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexDbHaKJltqOj9AvUnepg_j41M4bYbDDDfjwrWi4HEBK0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93_453_45m_45wacoz5NaD1bxGo8lfGeBOUt_45RhcarW4iudcczEMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsqvoStvLjD5lIleGOdA5Rk7LPk9x_45vIlq_zntRLVoERkMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useTQRTlNNx7jVCUmX39zvpYuZDh1UyqWqmZEqa4W8CX4IMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as index7zxJZcTTrB1p49T_45hcMmALI4WpQwIxEBvHxnSQvgo3wMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyKpx_456FkltA6kB5_T9s_45Rav82q5NUvxl_oeAYFIe8A5oMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordDfFkwb33qEzowU_XBYa2h0ICBb79rOI6Vf0zsPjcdIkMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexYKhC3Cxidt_yum_45sCWpcLo8TMfbvZZTaDqi3pEtv5_YMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index3iATpREkUZLgKBdZQn7QHozibio2G4hoxtBRfCOGr4gMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password7V_455b5gTU5pD71Yt7AjDpJzynkPVF6iHUR4KlrzorOEMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexI_0ku8_45lTlaUf6F6kiVNr9Lot1sSDevy3yoUXiIDpoUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93xXAw6CshuRAVNAJSi__45tPm8ANsd_HMIrothj6J4H_i0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesjLLGISCHP0y3ZJ2hoYRRP2f2umaqi6kFfesX3bQZaX0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45notice_45Y85hHPI_kBMbxso6uKvFwmiE8GVbFoWnGyp0nD7GA8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as index8ByQD0v_L2u5VWXyZSQvvvf_5Vigs6T_45YVl68nw3xW4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordf_NGukI80Jsi8_45P_45t_iy7NvoU_tJHb2J_455ZahYg8O9sMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backnLnSFpXRACPAwRBIBOzD11vowxzK3aL7Tsv_45AOl_pFcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeIcDXJsDu2NwwWZPaXxI_hdpcOvPKyLLEzZvekxQv9_45oMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexy4mqp6Us8tc4CyEfiWjWMX0vt5L8gQJaLu6BQBDnDVYMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93njOYmDo8UUcc5lQ4dpiw1p5nwfvd7w_45tatpfyCo2l3cMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45yourIDqtWQPWPlEziqZnbBZ2RiZebt2qTAfkwKGIF385fcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexKzEznsdIjD98hyE9p5PF8SzZmKXQH1MnMs7FB1iK5loMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexhiSEJ8YZE0MU34X0UXBHSsDXKyjFyLDqur1ZiihiFTwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93mL8gSQwJ06CVbDeDSULEdRsRSfALMlvVS2IR1MnubA0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexcQ2oYJ5WLCwp3p_45SeJLugzA2tiQHqC2S7pgpx8k2u9EMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexarhL6LO2tY046yxeSzBi0j_45UViSIEYlXr4zbc8hGI9UMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93EV3HMNAP0JSL7EOZ0bFwc2BDO7mp51jzYWIFI4AGzhMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackj74JY03xslTeUb83uyim7th1U64tX1Ir0MipJyi27oMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimYlilGb7H1anhgx_45deHOrS0knEpiEfUJu5VRbrXY_hYsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index7zm7mcBQ6Yd87WePUNy0PzRS28ZjXfokCHw6K1i5rtkMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youX8IhD1rNcRMOTCMwigvPXzIL_45tXYYzXFx53i4mB9sG0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errort_WlUrLh6yX0mDs1iB81xAx55Z1aaNah1XcFeFLP4e8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexSCL1J7drrNldwvmT5et3e69rl9rKb1rK__45KgT9ko73cMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexBDsldg7CcGPA7F1QltZUFa9KGZutQldzQe7cKxyAEG8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorIO8eoZHC9WK7EjKU8Ty7DcLP9SdoTft3tonMTt8XbrEMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93s23h9m_45jlBWF5ikhGwuxlU61VjT73dtS_eHzk7LB5zAMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsi13QHf9oG_RYS3tBK3pIAOS5fvT_45DeNAXoYMJm7o6xIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesCNDRZJ_45wNwP1GNw_45wRrjm0TW1ZjyiuQxOzPUjjOBtjIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93zuArIz83PXeYcWR5WKnhQmk5PL3MXHIUeUHmyRwsaRMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexiFRkus02yIkH6DDqPlK722U1p8DmrlwTm8eazC_45KqJkMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingshLOZSwAyD_450ckgYcP8_45t_45QO7e3ingjU4wnAFGA6DkRwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexFleFiorHX6TCSz0AoLbZIvtbjnkHoB_JmAtL_454UVXTMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatlRlRi6GKWqy51qXj8ENOd2kOkWWWrwKpyWQSL7aYt88Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleTRpr77PEfoizrW5dhU8sXcyTzW4e9F6UFKX46cCyrUQMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45younFi1BSW1D7JawB4jQLwy1t9oHvGw8EpjOoG1yPBZ9QEMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensive8v2kn_B5wbVu1z2Pi9Fkba0PVUnDRjXF1agyqQGJbIIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93XSicEgxGZHXgJvpYCvzcR2N6Yfirg1ecsyQ_45HD0aCAUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as add6S2CbuPo1f3mW3tSIgwnwD2BzzizcOmNO5vSFlHKUMIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexBFEyi54spyMemDlRW_45fKeQUpVbiqcpFQiWxwjnO5nLsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93ctN1F6j8hK5NG71UjWZtl_45kK9usk9sCTo_45vWzjeJH8YMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackhncT0J2XaFIFqMfWRL_45v4Hdq7f5KZOUY_9lWRoGo8jIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93ELr0y_45fu5_45_454ICyCFjkjOQt7Uiwkh_1iSAJ4w8tR89gMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youhidFfGB_45MNh6b3DWZSkrGVY7zLdcnp0YmBWegW1qVtsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bM6qT0w_45phO3smx2_FFbNZYmXnyPBfKOC6yvg7S28EdwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexEmG2ZubN0mZKFcrig3tG_SmHRw4UsAJU498pkLZoscoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93KzA0vfBPSUzh5qjEmKDlpQhJrQCh9uo5rS1PCBLnn5kMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93FRYcOVXvsRDR1yZ0w8f_45uEkTrZevJ__pX5UICR4J5hsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexQt9EVyIMsFOO2Q7CN26rnKOkl5vdcXMUNjrm62_45eLkUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addk5KJsWMprCf924zoWqSslynHsxgY_tEd5kjoIjb8m9gMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_937nbRIp4HECpIRINaXFj1Y1C7Qef1vFxn3g5wgSoqRl0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsjhc6y9B8XYfQFLjnoek_452DP838B6CGdLyW0Fo8g__LcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eictfA9wIMwF_45hryrs4yb6G32ieHPUcVuAtTxqXShxlm1sMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youL_q2_45ec8abSFDeYBX56XRalskXOtiBaz0BQFHqVA_jcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93rCHEfCjjEeCblR_mRN3p79SvaQxehBoYoal_45rnXYf_IMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93BVmvePC_45vmlzT_x0MQqjUW1hW26lbHQIqsxNvzTcszgMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step0yIHp_45vNxqpgp9vCjeq_458xQdAkgas3tkNl3lvvCTT_45ygMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailse1oG480PeWXIXN6ZEBva9P_45lEZ6xm3rdDGCbTHn3bGQMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results2aheML13YS_jTXePDop9SNVGYwWLyI6QZaa7WiVCAa4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexXE92lCTGbPikdZbQ9qfRdWP0jS3fML0hugeMepdYSSUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup5_AAR_4jyYuE_cCDN2yP9VgUO9E6kP7dgK373ykxSqMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youyo2hGi8vXsgLxEuLWcuXeyW6cWnHRmMw1x2oKKjYU5wMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93XsJ7bO_fcIumBQ9vM9pCovMuS8eXwMTxeC7oEqukLt0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_93u0O4Tf_2wP7rZVOHGwf3Lbm2vIdGDW23aTdkI_450bwwYMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93O3GfFOVYI8SXi4Er55MTscWQvvCj_45cMfEY9MmoMptuAMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0Ou3wB0q3Ev0_45EYAthURsgREzgkpkHe_45eS3uwk4vhmrsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersMrMir_zZ1eAWnzmsuD6CB9jEE_4517odKp_sQG8q_T1Y4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youERXjNfAPYB_LBkB0qYoHKBazwLJ9AmEp9D0UgVd3aFMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsFzngbEvqI1cA6nH72Bl9z2eZCG1uni4rKHUSa1MbL_UMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsosFFbLi8nstx87ng0CObOSguvNGnRvl0h_45HJ_47poAoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_934bUuu6QZxJc47fHzj79P30w7sbec9sMWJ5MvEVwvX58Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateD_45D1O1ouAPAs9KCFMScBTRStARlTM0BQI8a_45DXw2OjsMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93RGMeLE2_45e4QzgalZ9_45zjJN6A2jWO_452ZAa8YZQ5MI4EQMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsymkdiMEo_wGS0aso_tc4OhpaB_455wIhTGV7GjEqgsh4EMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0ZE5zJBhBcKPI99t_45wG5ZQZFajWjDyJLlcBt1DAOxPN8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexjAwNtyp_LATGwKvseoxBLDc23iTFOpZIYeSP4DiGsN0Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexhh_2F0Fo2ahGA7xJ_45yN97uSMTlDHVDNF_457jd2poIwrQMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsg_45xaRnmM3q3f_gsQaug0JqvkqhO8arHgZszQTZrnVK4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionssAws_oyZkxw3ArKTv3qrF7bf818thBPan09PNta1SA4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexGjs56VyDUWHPq02eBXDv7Q7D24d40S3DPspxzcx2YJ4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details_45to6QqVfryaLqke_45IUolOHwiG3c1C86Usx_457zMwsqk4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexzFPpTdVQ0zWCOi84_cbi5WzuHNYzEbCEmkvTcw5R_tcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as success8KUrmiebKRD0JnSVTt69kSLQCwqIGPGo_q2i0dUMW6EMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerkt2BCFdR3ZXcTCwDFB7Sw_45izJlc2I1fQjhe4mRUFPfwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailswrGRS1YDtwQm0uFOMDdmH1X8QIZtu8G_pqKOHdhLaoQMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourfHeBvoWhlOAAntXxqEi6W8V_65HSndqYWxmtRmGlgWUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93QyRN8XvZ2JqtONviKMdeMHey4zmRS7oVwtqZPClNFYcMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexIHRlyKN8iWbkfSA8QX6kTIqStIKwreNCup93qImAacwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93IO90Wi8eWHZwPaJ2wHaBrwUz4dZPbO7ztGdH_C21evoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as index9fE6YQ2cikVDv5NxzyQtD8jIjakRF1qgjiIUDmToEHgMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93WKtMHX__faEm5lNSgAmJfVAP75XmPANx7OZ2Ka4yc4QMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexfHafy4n_45_45EUUmFb3JxA6gIA_9Brmn518XScFWy_zz20Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as index13eei_JcKNg49mwB9Zd9AMbLbY1CjcJq0oHEbrSXQnIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsBPitpAoMAVC0Mwi07Ij_45ztxH_OBk_45_bQekW9EWbrOcoMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired3ag5Ow0mIIZpyTU8K3q_4506T_TvZHHhRE01rlBfrpRHMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexKWkLYMUACKygkL_VJGMHNnZLpeItaJq9saJe4hgVA_45IMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youcq2IBlTbC1fmQGKg71l8wzGA6k0F7vC56YoWEW5pUicMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsCDgJ26Xvuo2uVRVWkrUUaAaaRliqXXuM2BRNRSkyze4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationoD_cDHm8wYNrBbQfhLW9ozvGHUmZnVUxawyU7l_45nedwMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionAYqUB3lkTw312dqwNyh1SzJcy8WY0_YrUptUGwm2vOMMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45results4N2E4hEpWsgToEoKPz25TZRIooTUeGP0ZM7xnTCLbk4Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationYRGi9zQDVyxdHzdQqxYSvRP8jSs4wYWDvrE5veMCt7wMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93hLY_95mFOyEnraNxVEqlBxS3Rvh4DNlMEXEkSiPJh7UMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93_45TqZ_45fyDOskAG7XHPGkGudi62eapliv7Mox1C155D10Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93ngY2tPYBo_v6xBMJOl556xvo_45FCwbqmAPQ1U6GwAebIMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93h95WsphI9PkLShiKqxbDUspflz4ML_45pEx3jm5y4IGFAMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index7ojdRPDQQnCT_45LIWLXXTJzoBGoAQNWdHWgyE9Zry2OAMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsBYt7Nz63S_V1SyGDH_oEeCwrl1V_6OcbO6lkv3zaN8UMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93HQoT6mMhDGJNkkJoS_45PmP_45EKqissKJjznibUXzwdrkUMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93Si3n32e3JtB3cjCUlw2PFDxIV2Rs3dwqJRvs6h3Api8Meta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexwm7XZ2udAd8qDw5Ov_45fQym4Vj18zkSymc0O_vd4whtkMeta } from "/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEJJlFEB7TOPpkA6v_45U_Qx8Mihk_45kTWbbla9c0MiuZagMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerrCn_7oXc56TD1xXA9ykg1ef_45NKilWc_JhTGxpGNGlisMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordDfFkwb33qEzowU_XBYa2h0ICBb79rOI6Vf0zsPjcdIkMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index3iATpREkUZLgKBdZQn7QHozibio2G4hoxtBRfCOGr4gMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password7V_455b5gTU5pD71Yt7AjDpJzynkPVF6iHUR4KlrzorOEMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordf_NGukI80Jsi8_45P_45t_iy7NvoU_tJHb2J_455ZahYg8O9sMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93njOYmDo8UUcc5lQ4dpiw1p5nwfvd7w_45tatpfyCo2l3cMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexcQ2oYJ5WLCwp3p_45SeJLugzA2tiQHqC2S7pgpx8k2u9EMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index7zm7mcBQ6Yd87WePUNy0PzRS28ZjXfokCHw6K1i5rtkMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexBDsldg7CcGPA7F1QltZUFa9KGZutQldzQe7cKxyAEG8Meta?.props ?? {"verificationNeeded":true},
    meta: indexBDsldg7CcGPA7F1QltZUFa9KGZutQldzQe7cKxyAEG8Meta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingshLOZSwAyD_450ckgYcP8_45t_45QO7e3ingjU4wnAFGA6DkRwMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexFleFiorHX6TCSz0AoLbZIvtbjnkHoB_JmAtL_454UVXTMMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackhncT0J2XaFIFqMfWRL_45v4Hdq7f5KZOUY_9lWRoGo8jIMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateD_45D1O1ouAPAs9KCFMScBTRStARlTM0BQI8a_45DXw2OjsMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsg_45xaRnmM3q3f_gsQaug0JqvkqhO8arHgZszQTZrnVK4Meta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerkt2BCFdR3ZXcTCwDFB7Sw_45izJlc2I1fQjhe4mRUFPfwMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailswrGRS1YDtwQm0uFOMDdmH1X8QIZtu8G_pqKOHdhLaoQMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93QyRN8XvZ2JqtONviKMdeMHey4zmRS7oVwtqZPClNFYcMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionAYqUB3lkTw312dqwNyh1SzJcy8WY0_YrUptUGwm2vOMMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93hLY_95mFOyEnraNxVEqlBxS3Rvh4DNlMEXEkSiPJh7UMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93_45TqZ_45fyDOskAG7XHPGkGudi62eapliv7Mox1C155D10Meta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93h95WsphI9PkLShiKqxbDUspflz4ML_45pEx3jm5y4IGFAMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsBYt7Nz63S_V1SyGDH_oEeCwrl1V_6OcbO6lkv3zaN8UMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93HQoT6mMhDGJNkkJoS_45PmP_45EKqissKJjznibUXzwdrkUMeta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93Si3n32e3JtB3cjCUlw2PFDxIV2Rs3dwqJRvs6h3Api8Meta || {},
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1827319826/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]